
import { defineComponent } from 'vue'
import tableComponent from '@/components/table-component/index.vue';
import { queryAdvanceNote } from '@/api/advance.ts';

import { dateFormat } from '@/utils/util'
import { shortcuts } from '@/utils/global'
import { ElMessage } from 'element-plus'
import { cloneDeep } from 'lodash';
// import searchBox from './search-box.vue';

export default defineComponent({
    name: 'OrderList',
    props: {
        searchData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            queryData: {} as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'advanceDate',
                    label: '预支日期'
                },
                {
                    prop: 'serveCompanyCount',
                    label: '企业数'
                },
                {
                    prop: 'advanceNumber',
                    label: '人数'
                },
                {
                    prop: 'totalAdvanceAmount',
                    label: '总金额（元）',
                    color: '#FF7642'
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 120,
                    fixed: 'right'
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
        }
    },
    components: {
        tableComponent
    },
    watch: {
        searchData: {
            handler(newval) {
                if (newval) {
                    this.queryData = newval
                    // this.getData()
                }
            },
            // deep: true
        }
    },
    methods: {
        getData() {
            const query = cloneDeep(this.queryData)
            query.pageNum = this.pageInfo.pageNum
            query.pageSize = this.pageInfo.pageSize
            queryAdvanceNote(query).then((res: any) => {
                this.tableData = res.list || []
                this.pageInfo.total = res.totalCount
                this.$emit('countData', {
                    amount: res.totalAmount,
                    count: res.totalCount
                })
            })
        },
        handleSelectionChange(row: any) {
            const selects = row.map((i: any) => i.advanceDate)
            this.$emit('selects', selects)
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        toDetail(row: any) {
            this.$router.push({
                path: '/advance/applayDetail',
                query: {
                    date: row.advanceDate,
                    auditStatus: 1
                }
            })
        }
    }
})

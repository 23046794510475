
import { defineComponent } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
// import { advancePeopleList } from '@/api/advance.ts';
import { paymentRecordList, updateStatus } from '@/api/advance.ts';
import { statusMap, auditStatusMap } from '@/utils/map.ts';

console.log(212121, statusMap, auditStatusMap);

import tableComponent from '@/components/table-component/index.vue';
import dialogDetail from './dialog-detail.vue';
import drawerAdvanceDetail from './drawer-advance-detail.vue';

import { dateFormat } from '@/utils/util'
import { shortcuts } from '@/utils/global'
import { cloneDeep } from 'lodash';
// import searchBox from './search-box.vue';

export default defineComponent({
    name: 'OrderList',
    props: {
        searchData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            queryData: {} as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'userName',
                    label: '姓名'
                },
                {
                    prop: 'serveCompanyName',
                    label: '所属企业'
                },
                {
                    prop: 'idCardNo',
                    label: '身份证号'
                },
                {
                    prop: 'phone',
                    label: '手机号',
                },
                {
                    prop: 'applyRemark',
                    label: '备注',
                },
                {
                    prop: 'advanceApplyTime',
                    label: '申请时间',
                },
                {
                    prop: 'advanceAmount',
                    label: '预支金额',
                    prefix: '¥'
                },
                {
                    prop: 'auditStatus',
                    label: '审批状态',
                },
                {
                    prop: 'status',
                    label: '预支状态',
                    // map: statusMap
                },
                {
                    prop: 'billStatus',
                    label: '回单状态',
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 150,
                    fixed: 'right'
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            showDialog: false,
            showDrawer: false,
            statusMap: statusMap,
            auditStatusMap: auditStatusMap,
            userId: '',
            cardId: ''
        }
    },
    watch: {
        searchData: {
            handler(newval) {
                if (newval) {
                    this.queryData = newval
                    // this.getData()
                }
            },
            // deep: true
        }
    },
    components: {
        tableComponent,
        dialogDetail,
        drawerAdvanceDetail
    },
    methods: {
        getData() {
            const query = cloneDeep(this.queryData)
            query.pageNum = this.pageInfo.pageNum
            query.pageSize = this.pageInfo.pageSize
            console.log(1111, query);
            paymentRecordList(query).then((res: any) => {
                this.tableData = res.list
                this.pageInfo.total = res.total
                this.$emit('countData', {
                    amount: res.totalAmount,
                    count: res.totalCount
                })
            })
        },
        handleSelectionChange(row: any) {
            const selects = row.map((i: any) => i.id)
            this.$emit('selects', selects)
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        toDetail(row: any) {
            // this.$router.push({
            //     path: '/advance/applayDetail'
            // })
            this.showDialog = true
        },
        openDraw(row: any) {
            this.userId = row.userId
            this.cardId = row.idCardNo
            this.showDrawer = true
        },
        handleStop(row: any) {
            ElMessageBox.alert('停发后该人员将无法领取薪资，请确认后操作', '提示', {
                type: 'warning',
                confirmButtonText: '确定',
                callback: (action: any) => {
                    console.log(action);
                    if (action === 'confirm') {
                        updateStatus(row.id).then(res => {
                            ElMessage.success('保存成功')
                            this.getData()
                        })
                    }
                },
            })
        }
    }
})


import { defineComponent } from 'vue'
import searchComponent from '@/components/search-component/index.vue';
import { exportAdvanceNote, eleAdvanceApply, eleDownload, billApplyAdvanceMore, billDownloadAdvanceMore,paymentRecordExport } from '@/api/advance.ts';
import { statusOption, auditStatusOption } from '@/utils/map.ts';
import { closewin, downLoadFile } from '@/utils/util'

import orderList from './components/order-list.vue';
import peopleList from './components/people-list.vue';
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus';
import axios from 'axios';
import router from "@/router"

export default defineComponent({
    name: 'Sign',
    data() {
        return {
            type: 'all',
            projectCount: 0,
            peopleCount: 0,
            serveCompanyCount: 0,
            servePeopleCount: 0,
            searchList: {} as any,
            searchListAll: {
                queryTime: {
                    name: '申请时间',
                    prop: 'queryTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    defaultValue: [],
                },
                serveCompanyName: {
                    name: '企业/项目名称',
                    prop: 'serveCompanyName',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入企业/项目名称'
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            searchListNot: {
                serveCompanyName: {
                    name: '单位名称',
                    prop: 'serveCompanyName',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入派遣单位'
                },
                userName: {
                    name: '姓名',
                    prop: 'userName',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入姓名'
                },
                queryTime: {
                    name: '申请时间',
                    prop: 'queryTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    defaultValue: [],
                },
                phone: {
                    name: '手机',
                    prop: 'phone',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入手机号'
                },
                idCardNo: {
                    name: '身份证号',
                    prop: 'idCardNo',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入身份证号'
                },
                auditStatus: {
                    name: '审批状态',
                    prop: 'auditStatus',
                    type: 'select',
                    placeholder: '请选择审批状态',
                    value: '',
                    defaultValue: '',
                    options: auditStatusOption,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    },
                    handleFocus: async (item: any) => {
                    }
                },
                status: {
                    name: '预支状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择预支状态',
                    value: '',
                    defaultValue: '',
                    options: statusOption,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    },
                    handleFocus: async (item: any) => {
                    }
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            buttonListAll: [
            ] as any,
            buttonListNot: [
                // {
                //     name: '生成微信回单',
                //     icon: 'export',
                //     fn: () => {
                //     }
                // },
                // {
                //     name: '下载微信回单',
                //     icon: 'export',
                //     fn: () => {
                //     }
                // },
                // {
                //     name: '导出表格',
                //     icon: 'export',
                //     fn: () => {
                //     }
                // }
            ] as any,
            selectsAll: [] as any,
            selectsNot: [] as any,
            countData: {} as any
        }
    },
    components: {
        searchComponent,
        orderList,
        peopleList
    },
    computed: {
        buttonList() {
            const map = {
                all: this.buttonListAll,
                not: this.buttonListNot
            } as any
            return map[(this as any).type]
        }
    },
    beforeMount() {
        if (this.$route.query?.type) {
            this.type = this.$route.query?.type as string
        }
        if (this.$route.query?.status || this.$route.query?.status === '0') {
            this.searchListNot.status.value = Number(this.$route.query?.status)
        }
        if (this.$route.query?.auditStatus || this.$route.query?.auditStatus === '0') {
            this.searchListNot.auditStatus.value = Number(this.$route.query?.auditStatus)
        }
        // 被跳转到该页面恢复原页面数据
        if (/tip/.test(router.options.history.base)) {
            ElMessageBox.alert('回单正在下载中，您可以在该界面继续操作')
            const channel = new BroadcastChannel('new_tab_advancePeopleList')
            let isLoad = 0
            channel.onmessage = e => {
                const data = JSON.parse(e.data)
                console.log(333333, data);
                this.type = data.activeName
                isLoad = 1
                channel.postMessage('advancePeopleList_close')
                channel.close()
                this.searchListNot = data.searchList
                this.getData()
                    // ; (this as any).$refs.salaryPaid.getData()
            }
            setTimeout(() => {
                if (!isLoad) {
                    window.location.search = ''
                }
                channel.close()
            }, 5000)
        }
    },
    mounted() {
        setTimeout(() => {
            this.getData()
        }, 0);
    },
    methods: {
        // handleSearchList() {
        //     const map = {
        //         all: this.searchListAll,
        //         not: this.searchListNot
        //     } as any
        //     this.searchList = map[(this as any).type]
        // },
        handleClick(ev: any) {
            // this.handleSearchList()
            this.getData()
        },
        createQuery(searchList: any) {
            const query = {} as any
            for (const key in searchList) {
                if (searchList[key].type === 'dateRange') {
                    query[`${searchList[key].prop}Start`] = searchList[key].value?.length ? searchList[key].value[0] : ''
                    query[`${searchList[key].prop}End`] = searchList[key].value?.length ? searchList[key].value[1] : ''
                } else {
                    query[(searchList[key]).prop] = searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.createQuery(this.searchListAll)
            this.createQuery(this.searchListNot)
            setTimeout(() => {
                this.getData()
            },);
        },
        handleSelects(selects: any) {
            this.selectsAll = selects
        },
        handleSelectsNot(selects: any) {
            this.selectsNot = selects
        },
        onExportOrder() {
            const query = this.createQuery(this.searchListAll)
            query.dateList = this.selectsAll.join(',')
            exportAdvanceNote(query).then((res: any) => {
                window.location.href =
                    process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    res
                this.$message.success('导出成功！')
            })
        },
        onExport() {
            const query = this.createQuery(this.searchListNot)
            query.ids = this.selectsNot.join(',')
            paymentRecordExport(query).then((res: any) => {
                window.location.href =
                    process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    res
                this.$message.success('导出成功！')
            })
        },
        // 生成子弹回单
        handlerCreateZdRecipt() {
            const query = this.createQuery(this.searchListAll)
            const map = this.selectsAll.map((i: any) => i.replace(/-/g, ''))
            query.times = map.join(',')
            eleAdvanceApply(query).then(res => {
                this.$message.success('批量申请成功！')
            })
        },
        // 下载子弹回单
        handlerDownLoadZdRecipt() {
            const query = this.createQuery(this.searchListAll)
            const map = this.selectsAll.map((i: any) => i.replace(/-/g, ''))
            query.times = map.join(',')
            eleDownload(query).then(res => {
                window.location.href =
                    process.env.VUE_APP_BASE_URL +
                    '/common/downloadZip?delete=true&uuid=' +
                    res
                this.$message.success('导出成功！')
            })
        },
        // 生成微信回单
        handlerCreateWxRecipt() {
            if (!this.selectsNot?.length) {
                ElMessage.warning('请至少勾选一条数据')
                return
            }
            const query = this.createQuery(this.selectsNot)
            query.ids = this.selectsNot.join(',')
            billApplyAdvanceMore(query).then(res => {
                this.$message.success('已提交批量生成！')
            })

            // console.log(111111, this.selects);
        },
        // 下载微信回单
        handlerDownLoadWxRecipt() {
            if (!this.selectsNot?.length) {
                ElMessage.warning('请至少勾选一条数据')
                return
            }
            this.downloadReceiptOld()
        },

        async downloadReceiptOld() {
            const result = await billDownloadAdvanceMore({
                ids: this.selectsNot.join(','),
                ...this.createQuery(false)
            })
            this.windowOpen()
            document.title = '正在下载中...'
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-service/zip-oss/',
                method: 'post',
                data: result
            })
            window.location.href = files.data
            await this.$message.success('导出成功！')
            await loading.close()
            closewin()
        },
        windowOpen() {
            window.open(
                window.location.pathname +
                '?tip' + location.hash
            )
            const channel = new BroadcastChannel('new_tab_advancePeopleList')
            const timer = setInterval(() => {
                let data = {
                    activeName: 'not',
                    searchList: this.searchListNot
                } as any
                channel.postMessage(JSON.stringify(data))
                console.log('postMessage')
            }, 50)
            channel.onmessage = e => {
                if (e.data === 'advancePeopleList_close') {
                    clearInterval(timer)
                    channel.close()
                }
            }
        },
        handleCountData(data: any) {
            this.countData = data
        },
        getData() {
            if ((this as any).type === 'all') {
                (this as any).$refs?.order?.getData()
            } else if ((this as any).type === 'not') {
                (this as any).$refs?.people?.getData()
            }
        }
    }
})
